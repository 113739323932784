<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <select-language :selected-language="producData" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="producData.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
              >
                <b-form-group
                  label="Excerpt"
                  label-for="desc"
                >
                  <b-form-textarea
                    id="desc"
                    v-model="producData.exerpt"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Excerpt"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Descripption"
                label-for="blog-content"
                class="mb-2"
              >
                <quill-editor
                  id="blog-content"
                  v-model="producData.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="cat"
              >
                <b-form-group
                  label="Categories"
                  label-cols="2"
                  label-for="cat"
                >
                  <v-select
                    v-model="producData.productcategory"
                    label="name"
                    multiple
                    :options="categoriesList"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="cert"
              >
                <b-form-group
                  label="Certifications"
                  label-cols="2"
                  label-for="cert"
                >
                  <v-select
                    v-model="producData.certification"
                    label="name"
                    multiple
                    :options="certificationList"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        placeholder="Choose file"
                        @change="handleFile"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                variant="primary"
                class="mr-1"
                @click="updateProduct"
              >
                save
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect,
} from 'bootstrap-vue'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { required, alphaNum, email } from '@validations'
import axios from 'axios'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import products from '../products'
import productModule from '../productsModule'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

export default {
  components: {
    BCard,
    BMediaAside,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BImg,
    BFormFile,
    vSelect,
    selectLanguage,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      newProductData: null,
      producData: {},
      preview: null,
    }
  },
  mounted() {
  },
  setup() {
    // Get Categories List
    const fetchCategoriesList = () => store.dispatch('categoriesDetils/getProductCategory')
    fetchCategoriesList()
    const categoriesList = computed(() => store.state.categoriesDetils.productCategory)

    // Get Certifications List
    const fetchCertificationsList = () => store.dispatch('categoriesDetils/getCertificationsCategory')
    fetchCertificationsList()
    const certificationList = computed(() => store.state.categoriesDetils.certifications)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      formData,
      setFormData,
      categoriesList,
      certificationList,
    }
  },
  created() {
    this.showSingleProduct()
  },
  methods: {
    handleFile(event) {
      // eslint-disable-next-line prefer-destructuring
      this.producData.image = event.target.files[0]
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    updateProduct() {
      this.setNewProducteData()
      this.setFormData(this.newProductData)
      axios
        .post(`edit/product/${this.$router.currentRoute.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Edited Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$router.push('/products/list')
          }
          // eslint-disable-next-line no-empty
        })
    },
    setNewProducteData() {
      this.newProductData = {
        title: this.producData.title,
        exerpt: this.producData.exerpt,
        description: this.producData.description,
        productcategory: this.sendCategoryOrCertification(this.producData.productcategory),
        certification: this.sendCategoryOrCertification(this.producData.certification),
        image: this.producData.image,
        language_id: this.producData.language_id,
      }
    },
    sendCategoryOrCertification(category) {
      const id = _.map(category, 'id')
      return id
    },
    showSingleProduct() {
      axios.get(`get/product/${this.$router.currentRoute.params.id}`).then(res => {
        this.producData = res.data.success
        this.preview = res.data.success.image
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
