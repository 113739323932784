import axios from '@axios'

const token = localStorage.getItem('access_token')

export default {
  namespaced: true,
  state: {
    productsList: [],
    cat: [],
    cert: [],
    product: [],
    filteredProducts: '',
  },
  getters: {},
  mutations: {
    GET_PRODUCTS_LIST(state, data) {
      state.productsList = data
    },
    GET_CAT(state, cat) {
      state.cat = cat
    },
    GET_CERT(state, cert) {
      state.cert = cert
    },
    PRODUCT_SHOW(state, data) {
      state.product = data
    },
    GET_FILTERED_PRODUCTS(state, data) {
      state.filteredProducts = data
    },
  },
  actions: {
    async fetchCategories({ commit }) {
      const res = await axios.get('get/product/category', {
        headers: { Authorization: `Bearer ${token}` },
      })
      commit('GET_CAT', res.data.success.data)
    },
    async fetchCertifications({ commit }) {
      const res = await axios.get('get/certificate', {
        headers: { Authorization: `Bearer ${token}` },
      })
      commit('GET_CERT', res.data.success.data)
    },
    getFilteredProductLanguage({ commit }, { language_code }) {
      // eslint-disable-next-line camelcase
      axios.get(`https://gulfdb.technocloud.live/api/get/product?language_code=${language_code}`)
        .then(res => {
          commit('GET_FILTERED_PRODUCTS', res.data.success.data)
        })
        .catch(error => console.log(error))
    },
    addService(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('add/product', data, {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    showProduct({ commit }, { id }) {
      axios.get(`get/product/${id}`).then(res => {
        commit('PRODUCT_SHOW', res.data.success)
      })
    },
    updateProduct(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`edit/product/${id}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
