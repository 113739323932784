/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function products() {
  const toast = useToast()

  const productsTableColumns = [
    { key: 'id', sortable: true },
    { key: 'title', sortable: true },
    { key: 'exerpt', sortable: true },
    // { key: 'description', sortable: true },
    { key: 'certifications', sortable: true },
    { key: 'categories', sortable: true },
    { key: 'language', sortable: true },
    { key: 'actions' },
  ]

  const selectedLanguageCode = ref({
    code: '',
  })

  watchEffect(() => store.dispatch('productsModule/getFilteredProductLanguage', { language_code: selectedLanguageCode.value.code }))

  const entityData = computed(() => store.state.productsModule.filteredProducts)

  return {
    productsTableColumns,
    entityData,
    toast,
  }
}
